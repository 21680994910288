import { hasNextPage } from '@/components/modules/pages/serp/Pagination';
import {
  filterEmptyValues,
  getCategoryDescription,
  getCategoryTitleCTA,
  isSistaminuten,
  jsUcfirst,
  url,
} from '@/helpers';
import { SearchManagerOutput } from '@/hooks/search/useSearch';
import {
  DEFAULT_MY_LOCATION_KEYWORD,
  DEFAULT_SEARCH_KEYWORD,
  DEFAULT_SEARCH_LOCATION,
} from '@/hooks/search/useSearch.constants';
import { _s } from '@/locale';
import { SEARCH_RESULT_PER_PAGE_LIMIT } from '../constants';

function getSeoTitle(search: SearchManagerOutput): string {
  const parts = [];

  if (isSistaminuten()) {
    parts.push('Billigt - Upp till 50% rabatt - ');
  } else if (search.type === 'sistaminuten') {
    parts.push('Sista Minuten | Alltid med rabatt | ');
  }

  if (search.page > 0) {
    parts.push(_s('seo.page', { page: search.page }));
  }

  parts.push(getPageTitle(search));

  if (isSistaminuten()) {
    parts.push(' - sistaminutentider.se');
  } else {
    /**
     * if page is a predefined category page and has a custom CTA title, append it to the title
     * otherwise append the default - Bokadirekt
     */
    const keyword = url.decodeURIComponentSafe(search.q);
    const cta = getCategoryTitleCTA(keyword);

    parts.push(cta ? ` - ${cta}` : ' - Bokadirekt');
  }

  return parts.join('');
}

function getSeoDescription(search: SearchManagerOutput): string {
  const keyword = url.decodeURIComponentSafe(search.q);
  const location = url.decodeURIComponentSafe(search.location);

  const customDescription = getCategoryDescription(keyword, location);
  const generalDescription =
    getPageTitle(search) + _s(isSistaminuten() ? 'seo.sistaminuten.serpDescription' : 'seo.serpDescription');

  return (
    (search.page > 0
      ? _s('seo.from-to', {
          from: search.page * SEARCH_RESULT_PER_PAGE_LIMIT + 1,
          to:
            search.page * SEARCH_RESULT_PER_PAGE_LIMIT + SEARCH_RESULT_PER_PAGE_LIMIT > search.results
              ? search.results
              : search.page * SEARCH_RESULT_PER_PAGE_LIMIT + SEARCH_RESULT_PER_PAGE_LIMIT,
          of: search.results,
        })
      : '') + (customDescription ? customDescription : generalDescription)
  );
}

function getPageTitle(search: SearchManagerOutput): string {
  const title = [];
  if (search && search.q) {
    title.push(search.q);
    if (isSistaminuten()) {
      title.push('billigt');
    }
  } else {
    title.push(_s('BeautyAndHealth'));
  }

  if (search && search.location) {
    title.push(jsUcfirst(search.location));
  } else {
    title.push(_s('allSweden'));
  }

  return jsUcfirst(title.join(' '));
}

export function getSeoProps(search: SearchManagerOutput, location) {
  let pathname: string = undefined;

  if (search.type === 'sistaminuten') {
    pathname = 'sistaminuten/';
  }

  pathname += `${encodeURIComponent(search.q || DEFAULT_SEARCH_KEYWORD)}/${encodeURIComponent(
    search.location || DEFAULT_SEARCH_LOCATION,
  )}${location.search}`;

  const fullURL = `${url.getBaseUrl()}${pathname}`;

  const page = Math.max(0, Number(search.page) || 0);

  const prev = (() => {
    if (page) {
      return <link rel="prev" href={fullURL.replace(`page=${page}`, page === 1 ? '' : `page=${page - 1}`)} />;
    }
    return null;
  })();

  const next = (() => {
    if (!hasNextPage(search.results, page, SEARCH_RESULT_PER_PAGE_LIMIT)) {
      return null;
    }

    const hasPageParam = fullURL.indexOf('page') !== -1;
    const hasQueryParams = fullURL.indexOf('?') !== -1;

    let nextLink;

    if (!page || !hasPageParam) {
      nextLink = hasQueryParams ? fullURL.replace(/&$/, '') + `&page=${page + 1}` : fullURL + `?page=${page + 1}`;
    } else {
      nextLink = fullURL.replace(`page=${page}`, `page=${page + 1}`);
    }

    return <link rel="next" href={nextLink} />;
  })();

  return {
    title: getSeoTitle(search),
    description: getSeoDescription(search),
    keywords: '',
    url: fullURL,
    image: url.getBaseImageUrl(),
    prev,
    next,
  };
}

export function getSearchParamsForMatchedServices(search: SearchManagerOutput) {
  const fieldsRequired = ['q', 'startDate', 'endDate', 'timeOfDay', 'sort', 'prefs'];
  // delete extra keys
  Object.keys(search)
    .filter((key) => fieldsRequired.indexOf(key) === -1)
    .forEach((key) => {
      delete search[key];
    });
  return filterEmptyValues(search);
}

export function getSearchResultsPageHeadingProps(search: SearchManagerOutput): {
  title: string;
  description: string;
} {
  const keyword = url.decodeURIComponentSafe(search.q);
  const location = url.decodeURIComponentSafe(search.location);
  let description = getCategoryDescription(keyword, location && location.length > 0 ? location : undefined);

  try {
    // Remove "lymfmassage" when keyword is "massage" and gift card filter is active
    const isGiftCardFilterOn = search.prefs === '3';
    const isKeywordMassage = keyword === 'massage';

    if (isKeywordMassage && isGiftCardFilterOn && description?.includes('lymfmassage')) {
      description = description.replace('lymfmassage', '');
    }
  } catch (e) {}

  return {
    title: `${keyword || _s('BeautyAndHealth')} ${
      !location ? _s('allSweden') : location !== DEFAULT_MY_LOCATION_KEYWORD ? location : _s('fakeSearchNear')
    }`,
    description: description,
  };
}
