import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { SEARCH_RESULT_PER_PAGE_LIMIT } from '@/features/searchV2/constants';
import { getSearchPerformedTrackingProps, trackSearchPerformed } from '@/features/searchV2/helpers/tracking';
import { SearchContext } from '@/features/searchV2/types/tracking';
import {
  filterEmptyValues,
  getTrackingSearch,
  isSistaminuten,
  promiseWrapper,
  server,
  trackMpEvent,
  trackMpMetaEvent,
  url,
} from '@/helpers';
import { _s } from '@/locale';
import { SEARCH_ACTION, SearchState } from '@/reducers/searchV2Reducer';
import { placeService } from '@/services';
import { AppDispatch } from '@/store';
import { URL_FILTER_ID_MAP, UrlSearchState, urlSearchStateSchema } from '@/types/state/search';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { DEFAULT_MY_LOCATION_KEYWORD, DEFAULT_SEARCH_KEYWORD, DEFAULT_SEARCH_LOCATION } from './useSearch.constants';

export const positionSchema = z
  .string()
  .regex(
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)[-+][-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
    "Invalid position format. Must be in the format 'latitude-longitude', where latitude is between -90 and 90 and longitude is between -180 and 180.",
  );

export type Position = z.infer<typeof positionSchema>;

const searchTypeSchema = z.enum(['default', 'sistaminuten']);

export type SearchType = z.infer<typeof searchTypeSchema>;

function filterOnlyRequiredSearchParams(params) {
  if (params.location === DEFAULT_MY_LOCATION_KEYWORD) {
    delete params.location;
  }

  const fieldsRequiredOnServer = [
    'q',
    'location',
    'locationId',
    'position',
    'prefs',
    'bounds',
    'orderBy',
    'version',
    'startDate',
    'endDate',
    'timeOfDay',
    'sort',
    'page',
    'extend',
    'extendPage',
    'offset',
    'extendSearchIteration',
    'explain',
    'bundles',
    'startingScoreVersion',
  ];

  // delete extra keys
  Object.keys(params)
    .filter((key) => fieldsRequiredOnServer.indexOf(key) === -1)
    .forEach((key) => {
      delete params[key];
    });

  return filterEmptyValues(params);
}

export function trackSearchResultsPageView(params: UrlSearchState, search: SearchState) {
  const trackingPropsSearch = getTrackingSearch(
    {
      q: params.q,
      location: params.location,
      prefs: params.prefs,
    },
    search.results,
  );

  const has_raketen = search.topSearch?.places?.some((place) => place.topSearch) ? 'yes' : 'no';

  const screen_name = search.isSistaminutenSearch ? 'deals' : 'search_results';

  trackMpEvent('screen_shown', {
    screen_name,
    has_raketen,
    ...trackingPropsSearch,
  });

  if (trackingPropsSearch.query) {
    trackMpMetaEvent('Search', { search_string: trackingPropsSearch.query });
  }
}

export async function requestAndSetSearchResults(
  {
    type,
    params,
    state,
    resetAllSearchItems,
    bounds,
    searchContext,
  }: {
    type: SearchType;
    params: UrlSearchState;
    state: SearchState;
    resetAllSearchItems: boolean;
    bounds?: string;
    searchContext?: SearchContext;
  },
  dispatch: AppDispatch,
) {
  const _params = filterOnlyRequiredSearchParams({ ...params, bounds });

  dispatch({ type: SEARCH_ACTION.SET_BOUNDS, payload: bounds });
  dispatch({ type: SEARCH_ACTION.SET_FETCHING, payload: true });

  const searchEndpoint = type === 'sistaminuten' ? '/search/deals' : '/search';

  const { data: response, error } = await promiseWrapper(
    server.request.get(searchEndpoint, { params: _params, paramsSerializer: url.serialize }),
  );

  if (error) {
    toast(
      ({ closeToast }) => (
        <Snackbar type="danger" label={_s('serverError')} action={<button onClick={closeToast}>OK</button>} />
      ),
      { autoClose: 3000 },
    );
    dispatch({ type: SEARCH_ACTION.SET_FETCHING, payload: false });
    return;
  }

  if (isSistaminuten() && response.data?.topSearch) {
    delete response.data?.topSearch;
  }

  const {
    hits,
    topSearch = {},
    places,
    profilePages,
    version,
    extendSearchIteration,
    showInjectionNotice = false,
  } = response.data;

  const biddingPlaces = topSearch.places ? topSearch.places.filter((place) => place.topSearch) : [];

  if (biddingPlaces.length > 0) {
    placeService.storeImpressions(biddingPlaces.map((place) => place.topSearch.bidId));
  }

  const placesResult = (() => {
    if (resetAllSearchItems) {
      return { [params.page ?? 0]: [...(places || [])] };
    }

    return { ...state.places, [params.page ?? 0]: [...(places || [])] };
  })();

  const _searchContext = searchContext ?? state.searchContext ?? 'free_search';

  const payload: SearchState = {
    places: placesResult,
    results: hits?.value || 0,
    topSearch: topSearch || [],
    profilePages: profilePages || [],
    showInjectionNotice,
    extendSearchIteration: extendSearchIteration ?? 0,
    isSistaminutenSearch: type === 'sistaminuten',
    version,
  };

  if (type === 'sistaminuten') {
    payload['extendSearchDisabled'] = true;
  }

  trackSearchResultsPageView(params, { ...state, ...payload });

  trackSearchPerformed(
    getSearchPerformedTrackingProps({
      urlState: params,
      searchState: payload,
      context: _searchContext,
      screenName: type === 'sistaminuten' ? 'deals' : 'search_results',
    }),
  );

  /**
   * We never set searchContext to 'navigation' in the search state since that
   * should only be triggered by the navigation itself.
   * If the searchContext is not set in state, we set it to 'free_search' by default.
   */
  if (_searchContext === 'navigation') {
    payload['searchContext'] = 'free_search';
  } else {
    payload['searchContext'] = _searchContext;
  }

  dispatch({ type: SEARCH_ACTION.SET_RESULTS, payload });
}

export async function requestAndSetExtendedSearchResults(
  { type, params, state }: { type: SearchType; params: UrlSearchState; state: SearchState },
  dispatch: AppDispatch,
) {
  const { page } = params;
  const { extendSearchIteration = 0 } = state;
  const _params = {
    ...filterOnlyRequiredSearchParams({ ...params }),
    extend: true,
    limit: SEARCH_RESULT_PER_PAGE_LIMIT - state.places?.[page]?.length || 0,
    extendSearchIteration: extendSearchIteration + 1,
  };

  dispatch({ type: SEARCH_ACTION.SET_EXTEND_FETCHING, payload: true });

  const searchEndpoint = type === 'sistaminuten' ? '/search/deals' : '/search';

  const { data: response, error } = await promiseWrapper(
    server.request.get(searchEndpoint, { params: _params, paramsSerializer: url.serialize }),
  );

  if (error) {
    toast(
      ({ closeToast }) => {
        const handleOnClick = () => {
          closeToast();
          dispatch({ type: SEARCH_ACTION.SET_EXTEND_FETCHING, payload: false });
        };
        return (
          <Snackbar type="danger" label={_s('serverError')} action={<button onClick={handleOnClick}>OK</button>} />
        );
      },
      { onClose: () => dispatch({ type: SEARCH_ACTION.SET_EXTEND_FETCHING, payload: false }) },
    );
    return;
  }

  const { places: extendedPlaces = [] } = response.data;
  const results = extendedPlaces.length + state.results;

  dispatch({ type: SEARCH_ACTION.SET_EXTENDED_RESULTS, payload: { page, extendedPlaces, results } });
}

const URL_SEARCH_PARAM_KEYS = [
  'startDate',
  'endDate',
  'timeOfDay',
  'prefs',
  'sort',
  'page',
  'lat',
  'lon',
  'locationId',
  'sistaminuten',
] as const;

const SEARCH_PARAM_KEYS_SERVER_ONLY = ['orderBy', 'q', 'location', 'version', 'position'] as const;

export function buildServerRequestSearchParams(
  requestParams: UrlSearchState,
  urlState: UrlSearchState,
  searchState: SearchState,
) {
  const params = URL_SEARCH_PARAM_KEYS.reduce(
    (acc, key) => {
      if (key in requestParams) {
        const value = tryValidateUrlParam({ key, value: requestParams[key] });
        acc[key] = value ? `${value}` : undefined;
      } else {
        const value = tryValidateUrlParam({ key, value: urlState[key] });
        acc[key] = value ? `${value}` : undefined;
      }
      return acc;
    },
    {
      startDate: undefined,
      endDate: undefined,
      timeOfDay: undefined,
      lat: undefined,
      lon: undefined,
      sistaminuten: undefined,
    } as { [key in (typeof URL_SEARCH_PARAM_KEYS)[number]]: string },
  );

  SEARCH_PARAM_KEYS_SERVER_ONLY.forEach((key) => {
    if (key in searchState) {
      params[key] = searchState[key];
    }
  });

  if (params['lat'] && params['lon'] && positionSchema.safeParse(`${params['lat']}-${params['lon']}`).success) {
    params['position'] = `${params['lat']}-${params['lon']}`;
    delete params['lat'];
    delete params['lon'];
  }

  return params;
}

export function buildUrlSearchParams(requestParams: UrlSearchState, urlState: UrlSearchState): URLSearchParams {
  const urlSearchParams = new URLSearchParams();

  Object.entries({ ...urlState, ...requestParams }).forEach(([key, value]) => {
    const isServerKey = SEARCH_PARAM_KEYS_SERVER_ONLY.some((_key) => _key === key);

    if (isServerKey) {
      return;
    }

    if (key === 'prefs' && value) {
      const selected = `${value}`.split(',').map((id) => ({ id: +id }));

      for (const filter of selected) {
        if (URL_FILTER_ID_MAP[filter.id]) {
          urlSearchParams.append(URL_FILTER_ID_MAP[filter.id], '1');
        }
      }
    } else {
      if (value) {
        urlSearchParams.set(key, `${value}`);
      }
    }
  });

  return urlSearchParams;
}

/**
 * Check if search contains any search params, and if any new search params are added that needs sync url
 */
export function shouldSyncClientSearchQuery(requestParams: UrlSearchState, urlState: UrlSearchState): boolean {
  let sync = false;

  const changedSearchParams = URL_SEARCH_PARAM_KEYS.filter((key) => requestParams[key] !== undefined);

  for (const key of changedSearchParams) {
    if (requestParams[key] !== urlState[key]) {
      sync = true;
      break;
    }
  }

  return sync;
}

export function getSearchUrlParameters(props): UrlSearchState {
  let { q = '', location = '' } = props.match.params;
  if (!q && props.search && props.search.q) {
    q = props.search.q || '';
  }

  if (!location && props.search && props.search.location) {
    location = props.search.location || '';
  }
  q = q.toString();
  location = location.toString();

  return {
    q: q.toLowerCase() !== DEFAULT_SEARCH_KEYWORD ? url.decodeURIComponentWithPercent((q || '').trim()) : '',
    location:
      location.toLowerCase() !== DEFAULT_SEARCH_LOCATION && location.toLowerCase() !== 'var'
        ? url.decodeURIComponentWithPercent((location || '').trim())
        : '',
    locationId: url.getParamsFromGET()['locationId'],
    //@ts-ignore
    prefs: url.getFilters() || undefined,
    page: url.getParamsFromGET()['page'],
    startDate: url.getParamsFromGET()['startDate'],
    endDate: url.getParamsFromGET()['endDate'],
    extendPage: url.getParamsFromGET()['extendPage'],
    extend: url.getParamsFromGET()['extend'],
    offset: url.getParamsFromGET()['offset'],
    extendSearchIteration: url.getParamsFromGET()['extendSearchIteration'],
    timeOfDay: url.getParamsFromGET()['timeOfDay'],
    sort: url.getParamsFromGET()['sort'],
    lat: url.getParamsFromGET()['lat'],
    lon: url.getParamsFromGET()['lon'],
    explain: url.getParamsFromGET()['explain'],
    startingScoreVersion: url.getParamsFromGET()['startingScoreVersion'],
    sistaminuten: url.getParamsFromGET()['sistaminuten'],
  };
}

export function tryValidateUrlParam(param) {
  if (param.key === 'page' && param.value) {
    const valid = urlSearchStateSchema.safeParse({ page: Number(param.value) });
    return valid.success ? valid.data.page : undefined;
  }

  if (param.key === 'timeOfDay' && param.value) {
    const valid = urlSearchStateSchema.safeParse({ timeOfDay: param.value });
    return valid.success ? valid.data.timeOfDay : undefined;
  }

  if (param.key === 'sort' && param.value) {
    const valid = urlSearchStateSchema.safeParse({ sort: param.value });
    return valid.success ? valid.data.sort : undefined;
  }

  return param.value;
}
