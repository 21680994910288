import {
  allowTracking,
  fetchUserOnce,
  getCookie,
  getCustomerIOSiteId,
  getMainFbPixel,
  getTikTokPixel,
  hash,
  initTrackerCustomerIO,
  initTrackerFacebook,
  initTrackerTikTok,
  isServer,
  removeCookie,
  setMobile,
  unsetMobile,
  url,
} from '@/helpers';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AnyAction } from 'redux';
import { modalActions, userActions } from './actions';
import { featurePromoActions } from './actions/featurePromoActions';
import { SISTAMINUTEN_V2_FEATURE_FLAG } from './constants/experimentConstants';
import { useGetAmplitudeExperimentVariant } from './hooks/useAmplitudeExperiment';
import { AppDispatch } from './store';

const checkHash = (dispatch: AppDispatch) => hash.check(dispatch);
const initFacebookPixel = () => getMainFbPixel() && initTrackerFacebook(getMainFbPixel());
const initTikTokPixel = () => getTikTokPixel() && initTrackerTikTok(getTikTokPixel());
const initCustomerIO = () => {
  const siteId = getCustomerIOSiteId();
  if (!siteId) return;
  initTrackerCustomerIO(siteId);
};

export const initTracking = () => {
  if (!allowTracking()) return;
  sessionStorage.removeItem('disabledTrk');
  initFacebookPixel();
  initTikTokPixel();
  initCustomerIO();
};

const initNps = (dispatch: Dispatch<AnyAction>) => {
  const surveyId = getCookie('clientBooker');
  const npsClosed = getCookie('npsClosed');
  const show = Boolean(surveyId) && !Boolean(npsClosed);
  dispatch(modalActions.nps({ show }));
};

const addWindowListeners = (dispatch) => {
  window.addEventListener('pushstate', () => checkHash(dispatch), false);
  window.addEventListener('hashchange', () => checkHash(dispatch), false);

  return () => {
    window.removeEventListener('pushstate', () => checkHash(dispatch), false);
    window.removeEventListener('hashchange', () => checkHash(dispatch), false);
  };
};

const initCookies = (dispatch: AppDispatch, history) => {
  if (isServer) return;

  const replaceAutoLogin = () => history.replace({ search: url.changeGetParameter('auto-login') });
  const value = getCookie('LandingPage');
  if (value) {
    sessionStorage.setItem('LandingPage', value);
    removeCookie('LandingPage');
  }

  const token = getCookie('BokadirektMP') || getCookie('BokadirektOTT');

  if (token) {
    removeCookie('BokadirektMP');
    removeCookie('BokadirektOTT');
    // @ts-ignore
    dispatch(userActions.profile(token, true));
    setTimeout(replaceAutoLogin, 500);
    return;
  }

  if (window.location.hash === '#ZmV0Y2h1c2Vy') {
    // @ts-ignore
    dispatch(userActions.profile(null, null, true));
    window.location.hash = '';
    return;
  }
  // @ts-ignore
  if (fetchUserOnce()) dispatch(userActions.profile(null, null));
};

const useOnce = (callback) => {
  const result = useRef(null);

  if (result.current !== null) {
    return result.current;
  }

  result.current = callback();
  return result.current;
};

const captureTrackingData = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = {};

  ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach((key) => {
    const value = urlParams.get(key);
    if (value) {
      utmParams[key] = value;
    }
  });

  if (Object.keys(utmParams).length > 0) {
    sessionStorage.setItem('utmParams', JSON.stringify(utmParams));
  }

  sessionStorage.setItem('originalReferrer', document.referrer || 'direct');
};

const useStartupEvents = (isMobile: boolean, setVisible: Dispatch<SetStateAction<boolean>>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const useSistaminutenV2 = useGetAmplitudeExperimentVariant()(SISTAMINUTEN_V2_FEATURE_FLAG).value === 'on';

  isMobile ? setMobile() : unsetMobile();

  useOnce(() => {
    checkHash(dispatch);
    initTracking();
    initCookies(dispatch, history);
    return true;
  });

  useEffect(() => {
    if (useSistaminutenV2) {
      dispatch(featurePromoActions.updateFeaturePromoTrigger({ id: SISTAMINUTEN_V2_FEATURE_FLAG }));
    }
  }, []);
  useEffect(() => initNps(dispatch), []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => addWindowListeners(dispatch) && setVisible(true), []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => captureTrackingData(), []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useStartupEvents;
