import breakpoints from '@/breakpoints';
import Card from '@/components/elements/Card/Card';
import CardTags from '@/components/modules/place/CardTags';
import { StarRating } from '@/components/modules/rating';
import { config } from '@/config';
import { Z_INDEX } from '@/constants/styleConstants';
import withMobileView from '@/hoc/withMobileView';
import { useAppSelector } from '@/hooks';
import { PLACE_IMPRESSION, placeService } from '@/services';
import { useState } from 'react';
import {
  addStylesheet,
  classnames,
  getDateFormattedForFirstAvailableTime,
  getImagePreview,
  getPlaceImage,
  getTrackingPlace,
  getTrackingSearch,
  isEmpty,
  isMobile,
  isServer,
  isSistaminuten as isSistaminutenInstance,
  shouldDisplayReviews,
  splitCoordinates,
  trackMpEvent,
} from '../../../helpers';
import Carousel from './Carousel';
import placeStyles from './Map.module.scss';
import { Markers } from './Markers';

let MapContainer = () => null;
let TileLayer = () => null;
let FeatureGroup = () => null;
let latLngBounds = false;
let latLng = false;
let useMapEvents = () => null;
if (!isServer) {
  const RL = require('react-leaflet');
  const L = require('leaflet');
  MapContainer = RL.MapContainer;
  TileLayer = RL.TileLayer;
  FeatureGroup = RL.FeatureGroup;
  latLngBounds = L.latLngBounds;
  useMapEvents = RL.useMapEvents;
  latLng = L.latLng;
}

const getMapPosition = (isSistaminuten) => (isSistaminuten ? '124px' : isMobile() ? '184px' : '172px');

function DynamicMap(props) {
  const { useMapTiler = false } = props;
  const searchV2 = useAppSelector((state) => state.searchV2);
  const { places, fetching, position } = props;
  let { bounds } = searchV2;

  const search = { q: props.q, location: props.location, position: props.position, ...searchV2 };

  const [humanZoom, setHumanZoom] = useState(false);

  const isSistaminuten = isSistaminutenInstance() || searchV2.isSistaminutenSearch;

  const styles = {
    width: '100%',
    height: `calc(100vh - ${props.mapTopPos ?? getMapPosition(isSistaminuten)})`,
    minHeight: '300px',
    zIndex: Z_INDEX.MAP,
  };

  addStylesheet('https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.2.0/leaflet.css');
  const [carouselIndex, setCarouselIndex] = useState(0);

  function handleMouseMove(e) {
    if (!humanZoom) {
      setHumanZoom(true);
    }
  }

  function handleMoveEnd(e) {
    const mapBounds = JSON.stringify(e.target.getBounds());
    if (humanZoom && fetching === false && bounds !== mapBounds) {
      if (props?.onMapBoundsChange) {
        props.onMapBoundsChange(mapBounds);
        return;
      }
    }
  }

  const handleTrack = (name) => (e) => {};

  function handleZoomEnd(e) {
    if (config.env === 'production' || !(search?.debugLocation?.length > 0)) {
      handleTrack('zoom');
      setCarouselIndex(0);
      return handleMoveEnd(e);
    }
  }

  function handleDragEnd(e) {
    if (config.env === 'production' || !(search?.debugLocation?.length > 0)) {
      return handleMoveEnd(e);
    }
  }

  function handleClick(e) {
    handleTrack('click');
    handleMouseMove(e);
  }

  function MapEvents() {
    useMapEvents({
      dragend: handleDragEnd,
      zoomend: handleZoomEnd,
      click: handleClick,
      mousemove: handleMouseMove,
    });
    return null;
  }

  function shouldIncludeInMap(point) {
    return point.lat < 69.06 && point.lat > 55.336944 && point.lon < 24.166667 && point.lon > 10.9575;
  }

  if (fetching && !bounds) {
    return null;
  }
  let boundsPadding = { padding: [50, 50] };
  let mapBounds = undefined;
  let center = undefined; // place default center in Stockholm
  if (!isServer) {
    center = latLng(59.32421, 18.07144);
    if (bounds) {
      const boundsObj = JSON.parse(bounds);
      mapBounds = latLngBounds(
        latLng(boundsObj._southWest.lat, boundsObj._southWest.lng),
        latLng(boundsObj._northEast.lat, boundsObj._northEast.lng),
      );
      boundsPadding.padding = [0, 0];
    } else {
      mapBounds = latLngBounds();
      const userPosition = splitCoordinates(position); // hange with search position
      center = latLng(userPosition[0], userPosition[1]);

      if (places) {
        places.forEach((place, key) => {
          if (
            place.contact &&
            place.contact.position &&
            place.contact.position.lat &&
            place.contact.position.lon &&
            shouldIncludeInMap(place.contact.position)
          ) {
            const lat = place.contact.position.lat;
            const lon = place.contact.position.lon;
            mapBounds.extend(latLng(lat, lon));
          }
        });
      }

      if (isEmpty(mapBounds)) {
        mapBounds = latLngBounds(latLng(55.5979967, 11.9723388), latLng(65.5858387, 22.1545943)); // default to see most of Sweden
      }
    }
  }
  const columnClass = props.columnClass || '!w-5/12';

  const setting = {
    dragSpeed: 1.25,
    itemWidth: isServer ? (isMobile() ? breakpoints.sm : breakpoints.md) : window.innerWidth,
    itemSideOffsets: -15,
  };

  const itemStyle = {
    width: `${setting.itemWidth - 30}px`,
  };

  function handleTrackPlace(event, place) {
    const trackingProps = {
      screen_name: 'search_results_map',
      company_id: place.id,
    };
    trackMpEvent(event, { ...trackingProps, ...getTrackingPlace(place), ...getTrackingSearch(search) });

    placeService.storePlaceImpressions([place.id], PLACE_IMPRESSION.SERP_TO_PLACE_CLICK);
  }

  function getPlaceDetails(place) {
    let image = getPlaceImage(place);
    image = getImagePreview(image, '80x80', place.about.slug);

    const { rating, contact, about = {} } = place;
    const { subscriptionType } = about.settings;
    const address = contact && contact.address ? contact.address : null;
    const showAddress = address && (address.city || address.street);
    const showRatings = shouldDisplayReviews(place) && rating && rating.count > 4;
    const availableTime = subscriptionType !== 'essential' && getFirstAvailableTime(place);

    let href = `#details-${place.about.slug}-${place.id}`;

    if (searchV2.isSistaminutenSearch) {
      href += '?sistaminuten=1';
    }

    return (
      <a
        href={href}
        className="block min-h-[121px] w-full"
        onClick={() => {
          handleTrackPlace('company_clicked', place);
        }}>
        <div className="font-brown flex w-full flex-col gap-4">
          <div className="flex w-full flex-row">
            <span className="bg-black-100 mr-4 h-[60px] w-[60px] flex-none items-center overflow-hidden rounded-lg">
              {image && <img className="h-full object-contain" src={image} alt={place.about.name} />}
              {!image && (
                <span className="text-black-900 flex h-[60px] w-[60px] items-center justify-center text-3xl">
                  {place.about.name && place.about.name.charAt(0)}
                </span>
              )}
            </span>
            <div className="my-auto" style={{ minWidth: 0 }}>
              <h3 className="text-black-900 min-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap text-base font-semibold">
                {place.about.name}
              </h3>

              <div className="overflow-hidden text-ellipsis">
                {showRatings ? (
                  <StarRating count={rating.count} rating={rating.score} justCount={true} />
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>

              {showAddress && (
                <div className="text-black-600 overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                  <span>{address && address.street ? address.street.trim() : ''}</span>
                  {address && address.street && address.city ? ', ' : ''}
                  <span>{address && address.city ? address.city.trim() : ''}</span>
                </div>
              )}
              {availableTime ? availableTime : <span className="text-brand-700 block text-sm">&nbsp;</span>}
            </div>
          </div>
          <CardTags place={place} />
        </div>
      </a>
    );
  }

  function getFirstAvailableTime(place) {
    const matchedService = place.matchedServices && place.matchedServices[0] ? place.matchedServices[0] : null;
    const availability =
      matchedService && matchedService.availability && matchedService.availability[0]
        ? matchedService.availability[0]
        : null;

    if (!availability) {
      return null;
    }

    return (
      <span className="text-brand-500 block text-sm">
        {getDateFormattedForFirstAvailableTime(availability, place.timezone)}
      </span>
    );
  }

  const { mapId = 'streets-v2', apiKey } = config.mapTiler;

  return (
    <div
      className={`fixed top-[${props.mapTopPos ?? getMapPosition(isSistaminuten)}] bottom-0 right-0 bg-cover ${
        props.mapWidth ?? columnClass
      }`}
      style={styles}>
      <MapContainer
        style={{ height: styles.height }}
        center={center}
        zoomControl={true}
        bounds={mapBounds}
        boundsOptions={boundsPadding}>
        {useMapTiler ? (
          <TileLayer
            url={`https://api.maptiler.com/maps/${mapId}/{z}/{x}/{y}@2x.png?key=${apiKey}`}
            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a>'
            maxZoom={17}
            minZoom={5}
          />
        ) : (
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            maxZoom={17}
            minZoom={5}
          />
        )}
        <FeatureGroup>
          <Markers
            places={places}
            setCarouselIndex={setCarouselIndex}
            carouselIndex={carouselIndex}
            getPlaceDetails={getPlaceDetails}
          />
        </FeatureGroup>
        <MapEvents />
      </MapContainer>
      {props.isMobileView && places && (
        <div id="place-details" className={classnames(placeStyles.placeDetails, props.carouselClassname ?? '')}>
          {/* {placeDetails} */}
          <Carousel _data={places} {...setting} carouselIndex={carouselIndex} setCarouselIndex={setCarouselIndex}>
            {places.map((place, _i) => (
              <div className={placeStyles.carouselItem} key={_i} style={{ ...itemStyle }}>
                <Card>
                  <div className={placeStyles.carouselItemDetails}>{getPlaceDetails(place)}</div>
                </Card>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}

const Maps = withMobileView(DynamicMap);

export default Maps;
