import { Button } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import FakeSearchInput from '@/components/modules/mobile/FakeSearchInput';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import { DEFAULT_SEARCH_KEYWORD, DEFAULT_SEARCH_LOCATION } from '@/hooks/search/useSearch.constants';
import { _s } from '@/locale';
import { urlSearchStateSchema } from '@/types/state/search';
import { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import KeywordAutoSuggest from '../../components/AutoSuggest/KeywordAutoSuggest/KeywordAutoSuggest';
import useKeywordAutoSuggestManager from '../../components/AutoSuggest/KeywordAutoSuggest/KeywordAutoSuggest.hooks';
import LocationAutosuggest from '../../components/AutoSuggest/LocationAutoSuggest/LocationAutoSuggest';
import useLocationAutoSuggestManager from '../../components/AutoSuggest/LocationAutoSuggest/LocationAutoSuggest.hooks';
import {
  AutoSuggestInputComponent,
  KeywordAutoSuggestSectionTitle,
  KeywordSuggestionItem,
  LocationAutoSuggestSectionTitle,
  LocationSuggestionItem,
} from '../../components/AutoSuggest/shared/templates';
import { AutoSuggestInputTheme } from '../../components/AutoSuggest/shared/types';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import { getDateTimeFilterLabel } from '../../components/DateTimePicker/DateTimePicker.helpers';
import useDateTimePickerManager from '../../components/DateTimePicker/DateTimePicker.hooks';
import { SearchContext } from '../../types/tracking';

const AUTOGUSGEST_THEME: AutoSuggestInputTheme = {
  container: 'relative w-full',
  suggestionsContainer: 'absolute z-[100] w-full right-0 left-0 max-h-[550px]',
  suggestionsContainerOpen: 'z-[100] bg-white p-sm mt-xs rounded-md max-h-[550px] overflow-y-auto',
};

const baseTranslationKey = 'features.searchV2.pages.search.SearchPage';

function getSearchFiltersFromSearchQuery(searchQuery: string) {
  const searchParams = new URLSearchParams(searchQuery);
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const validStartDate = urlSearchStateSchema.safeParse({ startDate: params['startDate'] }).success;
  const validEndDate = urlSearchStateSchema.safeParse({ endDate: params['endDate'] }).success;
  const validTimeOfDay = urlSearchStateSchema.safeParse({ timeOfDay: params['timeOfDay'] }).success;

  return {
    startDate: validStartDate ? params['startDate'] : undefined,
    endDate: validEndDate ? params['endDate'] : undefined,
    timeOfDay: validTimeOfDay ? params['timeOfDay'] : undefined,
    locationId: params['locationId'] || undefined,
    lat: params['lat'] || undefined,
    lon: params['lon'] || undefined,
  };
}

const SearchPage = () => {
  const location = useLocation();
  const history = useHistory();
  const keywordInputRef = useRef(null);
  const locationInputRef = useRef(null);
  const searchFilters = getSearchFiltersFromSearchQuery(location.search);
  const [searchContext, setSearchContext] = useState<SearchContext>(
    location?.state?.fromLandingPage ? 'home_page_free_search' : undefined,
  );

  const isSistaminutenSearch = Boolean(location?.state?.isSistaminutenSearch);

  const initialState = {
    keyword: location?.state?.q,
    location: location?.state?.location,
    locationId: searchFilters.locationId,
    lat: searchFilters.lat,
    lon: searchFilters.lon,
    startDate: searchFilters.startDate,
    endDate: searchFilters.endDate,
    timeOfDay: searchFilters.timeOfDay,
  };

  const [isOpenKeywordAutoSuggest, setIsOpenKeywordAutoSuggest] = useState<boolean>(!initialState.keyword);
  const [isOpenLocationAutoSuggest, setIsOpenLocationAutoSuggest] = useState<boolean>(false);
  const [isOpenDateTimePicker, setIsOpenDateTimePicker] = useState<boolean>(false);

  const keywordAutoSuggestManager = useKeywordAutoSuggestManager({
    initialState: {
      keyword: initialState.keyword ?? '',
      suggestions: [],
      newServices: [],
      showSuggestions: true,
    },
    onSelectKeywordSuggestion: handleOnSelectKeywordSuggestion,
  });

  const locationAutoSuggestManager = useLocationAutoSuggestManager({
    initialState: {
      locationValue: initialState.location ?? '',
      locationId: initialState.locationId,
      lat: initialState.lat,
      lon: initialState.lon,
      hasHistory: false,
      suggestions: [],
      storedSuggestions: [],
      showSuggestions: true,
      isFetchingUserPosition: false,
    },
    onSelectLocationSuggestion: handleOnSelectLocationSuggestion,
  });

  const dateTimePickerManager = useDateTimePickerManager({
    initialState: {
      startDate: initialState.startDate,
      endDate: initialState.endDate,
      timeOfDay: initialState.timeOfDay,
      showCalendar: true,
    },
    onUpdateDateTime: handleOnUpdateDateTime,
    onClearDateTime: handleOnClearDateTime,
  });

  function handleOnUpdateDateTime() {
    setIsOpenDateTimePicker(false);
  }
  function handleOnClearDateTime() {
    dateTimePickerManager.onChangeDateTime({ startDate: null, endDate: null, timeOfDay: null });
    setIsOpenDateTimePicker(true);
  }

  function handleOnSelectKeywordSuggestion() {
    if (location?.state.fromLandingPage) {
      setSearchContext('home_page_search_autocomplete');
    } else {
      setSearchContext('search_autocomplete');
    }

    setIsOpenKeywordAutoSuggest(false);
  }

  function handleOnSelectLocationSuggestion() {
    setIsOpenLocationAutoSuggest(false);
  }

  function handleOpenKeywordAutoSuggest() {
    setIsOpenKeywordAutoSuggest(true);
  }

  function handleOnCloseKeywordSuggestion() {
    setIsOpenKeywordAutoSuggest(false);
  }

  function handleOpenLocationAutoSuggest() {
    setIsOpenLocationAutoSuggest(true);
  }

  function handleOnCloseLocationSuggestion() {
    setIsOpenLocationAutoSuggest(false);
  }

  function handleOpenDateTimePicker() {
    setIsOpenDateTimePicker(true);
  }

  function handleOnCloseDateTimeSuggestion() {
    setIsOpenDateTimePicker(false);
  }

  function handleOnClearKeyword() {
    keywordAutoSuggestManager.onChange(null, { newValue: '' });
    setSearchContext(undefined);
    setIsOpenKeywordAutoSuggest(true);
  }

  function handleOnClearLocation() {
    locationAutoSuggestManager.onChange({ target: { value: '' } });
    setIsOpenLocationAutoSuggest(true);
  }

  function handleOnBlurLocationAutoSuggest(event, suggestion) {
    locationAutoSuggestManager.onBlur(event, suggestion);
    setIsOpenLocationAutoSuggest(false);
  }

  function handleOnBlurKeywordAutoSuggest(event, suggestion) {
    keywordAutoSuggestManager.onBlur(event, suggestion);
    setIsOpenKeywordAutoSuggest(false);
  }

  function handleOnAutoSuggestKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      setIsOpenLocationAutoSuggest(false);
      setIsOpenKeywordAutoSuggest(false);
    }
  }

  function handlePerformSearch() {
    const state = {
      keyword: keywordAutoSuggestManager.keyword || DEFAULT_SEARCH_KEYWORD,
      location: locationAutoSuggestManager.location || DEFAULT_SEARCH_LOCATION,
    };

    const { startDate, endDate, timeOfDay } = dateTimePickerManager;
    const query = new URLSearchParams(location.search);
    /**
     * override existing query params for dateTime filters.
     * if they are set to null then they are cleared and
     * should be removed from the query string.
     */
    startDate ? query.set('startDate', startDate) : query.delete('startDate');
    startDate ? query.set('endDate', endDate) : query.delete('endDate');
    timeOfDay ? query.set('timeOfDay', timeOfDay) : query.delete('timeOfDay');

    /**
     * Override existing query params for locationId and lat/lon
     *
     * If locationId exist, ignore the lat/lon values
     */
    const { locationId, lat, lon } = locationAutoSuggestManager;

    if (locationId) {
      query.set('locationId', locationId);
      query.delete('lat');
      query.delete('lon');
    } else {
      if (lat && lon) {
        query.set('lat', lat);
        query.set('lon', lon);
      } else {
        query.delete('lat');
        query.delete('lon');
      }

      query.delete('locationId');
    }

    let pathname = `/${state.keyword}/${state.location}`;

    if (isSistaminutenSearch) {
      pathname = `/sistaminuten/${state.keyword}/${state.location}`;
    }

    /**
     * if keyword input is dirty, the 'searchContext' state is set
     * otherwise if it is not dirty, or have been cleared then we set the searchContext to 'free_search'
     * or 'home_page_free_search' if the user is coming from the landing page
     */
    let _searchContext: SearchContext = undefined;

    if (searchContext) {
      _searchContext = searchContext;
    } else if (location?.state?.fromLandingPage) {
      _searchContext = 'home_page_free_search';
    } else {
      _searchContext = 'free_search';
    }

    history.push({
      pathname,
      search: query.toString(),
      state: { searchContext: _searchContext },
    });
  }

  return (
    <div className="px-md">
      <header className="pt-md relative flex h-[40px] items-center justify-center">
        <button className="absolute left-0" onClick={() => history.goBack()}>
          <Icon variant="back" />
        </button>
        <h1 className="font-semibold">{isSistaminutenSearch ? `${_s('search')} Sista Minuten` : _s('search')}</h1>
      </header>
      <div className="space-y-sm pt-md">
        <FakeSearchInput
          isLoading={false}
          onClick={handleOpenKeywordAutoSuggest}
          valid={keywordAutoSuggestManager.keyword.length > 0}
          icon="search"
          onClear={handleOnClearKeyword}
          content={keywordAutoSuggestManager.keyword || _s('Enter service or salon')}
          size="sm"
        />
        <FakeSearchInput
          isLoading={false}
          onClick={handleOpenLocationAutoSuggest}
          valid={locationAutoSuggestManager.location.length > 0}
          icon="location"
          onClear={handleOnClearLocation}
          content={locationAutoSuggestManager.location || _s('Enter city or area')}
          size="sm"
        />
        {!isSistaminutenSearch && (
          <FakeSearchInput
            isLoading={false}
            onClick={handleOpenDateTimePicker}
            valid={Boolean(
              dateTimePickerManager.startDate || dateTimePickerManager.endDate || dateTimePickerManager.timeOfDay,
            )}
            icon="calendar-events"
            onClear={handleOnClearDateTime}
            content={getDateTimeFilterLabel(
              dateTimePickerManager.startDate,
              dateTimePickerManager.endDate,
              dateTimePickerManager.timeOfDay,
            )}
            size="sm"
          />
        )}

        <Modal isOpen={isOpenKeywordAutoSuggest}>
          <Modal.Content floating={false}>
            <div className="px-md">
              <KeywordAutoSuggest
                inputRef={keywordInputRef}
                {...keywordAutoSuggestManager}
                onBlur={handleOnBlurKeywordAutoSuggest}
                onKeyDown={handleOnAutoSuggestKeyDown}
                theme={AUTOGUSGEST_THEME}
                renderInputComponent={({ key, ...inputProps }) => {
                  return (
                    <AutoSuggestInputComponent
                      key={key}
                      inputProps={{ ...inputProps, autoFocus: true }}
                      className="border-brand-500 rounded-full"
                      onClear={handleOnClearKeyword}
                      leftSlot={
                        <button className="flex items-center" onClick={handleOnCloseKeywordSuggestion}>
                          <Icon variant="back" />
                        </button>
                      }
                    />
                  );
                }}
                renderSuggestion={(suggestion) => <KeywordSuggestionItem suggestion={suggestion} />}
                renderSectionTitle={(section) => <KeywordAutoSuggestSectionTitle {...section} />}
              />
            </div>
          </Modal.Content>
        </Modal>

        <Modal isOpen={isOpenLocationAutoSuggest}>
          <Modal.Content floating={false}>
            <div className="px-md">
              <LocationAutosuggest
                inputRef={locationInputRef}
                {...locationAutoSuggestManager}
                onBlur={handleOnBlurLocationAutoSuggest}
                onKeyDown={handleOnAutoSuggestKeyDown}
                theme={AUTOGUSGEST_THEME}
                renderInputComponent={({ key, ...inputProps }) => {
                  return (
                    <AutoSuggestInputComponent
                      key={key}
                      inputProps={{ ...inputProps, autoFocus: true }}
                      className="border-brand-500 rounded-full"
                      onClear={handleOnClearLocation}
                      leftSlot={
                        <button className="flex items-center" onClick={handleOnCloseLocationSuggestion}>
                          <Icon variant="back" />
                        </button>
                      }
                    />
                  );
                }}
                renderSuggestion={(suggestion) => (
                  <LocationSuggestionItem suggestion={suggestion} onRemoveHistorySuggestion={() => null} />
                )}
                renderSectionTitle={(section) => <LocationAutoSuggestSectionTitle {...section} />}
              />
            </div>
          </Modal.Content>
        </Modal>

        <Modal isOpen={isOpenDateTimePicker}>
          <Modal.Content floating={false}>
            <div className="px-md">
              <DateTimePicker
                {...dateTimePickerManager}
                renderInputComponent={({ hasFilterApplied, ...props }) => {
                  return (
                    <div className="px-md border-brand-500 relative flex items-center rounded-full border">
                      <button className="flex items-center" onClick={handleOnCloseDateTimeSuggestion}>
                        <Icon variant="back" />
                      </button>
                      <input {...props} />
                      {hasFilterApplied && (
                        <button
                          onClick={dateTimePickerManager.onClearDateTime}
                          onMouseDown={dateTimePickerManager.onClearDateTime}
                          className="absolute right-0 top-0 z-10 h-full !w-auto px-4">
                          <Icon variant="close-circle" />
                        </button>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </Modal.Content>
        </Modal>

        <Button size="lg" block onClick={handlePerformSearch}>
          {isSistaminutenSearch ? `${_s('search')} Sista Minuten` : _s('search')}
        </Button>
        {isSistaminutenSearch && (
          <p className="text-m py-xs text-center">{_s(`${baseTranslationKey}.sistaminutenSearchDescription`)}</p>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
