import Card from '@/components/elements/Card/Card';
import { Button, Fab } from '@/components/elements/forms/buttons';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import Rating from '@/components/elements/redesign/Rating/Rating';
import Icon from '@/components/icons/Icon';
import EmptyState from '@/features/ProfilePages/components/EmptyState';
import { promiseWrapper, server } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import { __, _s } from '@/locale';
import { reviewsServices } from '@/services';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ReviewCard from '../../../components/elements/Card/ReviewCard';
import { getAuthorInitials } from '../helpers/review';
import ReviewModal from '../modules/ReviewModal';

const baseTranslationKey = 'features.ProfilePages.components.Reviews';

type UseReviewsProps = {
  placeId: string;
  tooltipTargetRef: React.RefObject<HTMLDivElement>;
};

const UseReviews = ({ placeId, tooltipTargetRef }: UseReviewsProps) => {
  const { isMobileView } = useMobileView();
  const [showAddReviewModal, setShowAddReviewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rating, setRating] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [reviews, setReviews] = useState([]);
  const haveReviews = Boolean(reviews?.length);

  useEffect(() => {
    fetchReviews();
    getReviewsAverage();
  }, [page, placeId]);

  const fetchReviews = async () => {
    let timeoutId: NodeJS.Timeout;

    timeoutId = setTimeout(() => {
      setIsLoading(true);
    }, 1000);

    const { data, error } = await promiseWrapper(reviewsServices.getPlaceReviews(Number(placeId), page, 4));

    if (error) {
      Sentry.captureException(error);
    }

    setReviews(data.reviews);
    setTotalPages(data.totalPages);
    setTotalReviews(data.totalReviews);

    clearTimeout(timeoutId);
    setIsLoading(false);
  };

  const getReviewsAverage = async () => {
    const { data } = await promiseWrapper(server.request.get('/places/reviews/summary/' + placeId));
    setRating(data.data.averageScore);
  };

  const handleClickAddReview = () => {
    setShowAddReviewModal((prev) => !prev);
  };

  const handleCloseAddReviewModal = () => {
    setShowAddReviewModal(false);
  };

  const handleClickNextPage = () => {
    setPage((prev) => prev + 1);
  };

  const handleClickPreviousPage = () => {
    setPage((prev) => prev - 1);
  };

  const handleChangeReviews = () => {
    fetchReviews();
    getReviewsAverage();
  };

  const handleDeleteReview = async (id: string) => {
    let timeoutId: NodeJS.Timeout;

    try {
      timeoutId = setTimeout(() => {
        setIsLoading(true);
      }, 1000);

      const { error } = await promiseWrapper(reviewsServices.deleteExternal(id));
      if (!error) {
        setShowAddReviewModal(false);
        toast(({ closeToast }) => (
          <Snackbar type="success" label={__(`${baseTranslationKey}.alert.success`)} onClose={closeToast} />
        ));
        fetchReviews();
        getReviewsAverage();
      } else {
        toast(({ closeToast }) => (
          <Snackbar type="danger" label={__(`${baseTranslationKey}.alert.error`)} onClose={closeToast} />
        ));
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      clearTimeout(timeoutId);
      setIsLoading(false);
    }
  };

  return {
    render: (
      <>
        <Card size={isMobileView ? 'lg' : 'xl'}>
          {isLoading && <LoadingPlaceHolder />}
          {!isLoading && (
            <div className={`${isMobileView ? 'gap-xl' : 'gap-2xl'} flex flex-col`}>
              <div className={`flex  ${haveReviews && isMobileView ? 'gap-xl flex-col' : 'justify-between'}`}>
                <Rating
                  count={totalReviews}
                  rating={rating}
                  className={`${isMobileView ? 'text-h-l' : 'text-h-xl'}`}
                  size="lg"
                />
                {(!isMobileView || haveReviews) && (
                  <Button
                    size="md"
                    variant={haveReviews ? 'primary' : 'secondary'}
                    label={_s(`${baseTranslationKey}.cta`)}
                    iconNoFilter
                    onClick={handleClickAddReview}
                    rightIcon={<Icon variant="pen" color={haveReviews ? 'fg_primary_inverse' : 'fg_primary'} />}
                  />
                )}
              </div>
              {!haveReviews && (
                <EmptyState
                  body={_s(`${baseTranslationKey}.emptyState.body`)}
                  title={_s(`${baseTranslationKey}.emptyState.title`)}
                  icon={<img src="/images/illustrations/empty.svg" alt="" />}
                />
              )}
              {haveReviews && (
                <div className="gap-md flex flex-col">
                  {reviews.map((review, index) => {
                    const initials = getAuthorInitials(review.review.author);
                    return (
                      <ReviewCard
                        tooltipTargetRef={tooltipTargetRef}
                        verified={review.verified}
                        key={index}
                        placeId={placeId}
                        reviewId={review.id}
                        onEdit={review.canEdit ? handleChangeReviews : undefined}
                        onDelete={handleDeleteReview}
                        companyReply={review.companyReply ? { ...review.companyReply } : undefined}
                        review={{ ...review.review, initials }}
                      />
                    );
                  })}
                </div>
              )}
              {haveReviews && totalReviews > 4 && (
                <div className="flex justify-between">
                  <Fab
                    icon={<Icon variant="chevron-left" color={page === 1 ? 'fg_disabled' : 'fg_primary'} />}
                    onClick={handleClickPreviousPage}
                    size="sm"
                    disabled={page === 1}
                    variant="secondary"
                  />
                  <div className="text-fg_secondary">
                    <span>{_s(`${baseTranslationKey}.pagination.page`)}</span> <span>{page}</span>{' '}
                    <span>{_s(`${baseTranslationKey}.pagination.of`)}</span>{' '}
                    <span>{totalPages === 0 ? 1 : totalPages}</span>
                  </div>
                  <Fab
                    icon={
                      <Icon
                        variant="chevron-right"
                        color={page === totalPages || totalPages === 0 ? 'fg_disabled' : 'fg_primary'}
                      />
                    }
                    onClick={handleClickNextPage}
                    disabled={page === totalPages || totalPages === 0}
                    size="sm"
                    variant="secondary"
                  />
                </div>
              )}
              {isMobileView && !haveReviews && (
                <Button
                  size="md"
                  variant="secondary"
                  onClick={handleClickAddReview}
                  iconNoFilter
                  label={_s(`${baseTranslationKey}.cta`)}
                  rightIcon={<Icon variant="pen" color="fg_primary" />}
                />
              )}
            </div>
          )}
        </Card>
        {showAddReviewModal && (
          <ReviewModal onClose={handleCloseAddReviewModal} placeId={placeId} onChangeReviews={handleChangeReviews} />
        )}
      </>
    ),
    getTotalReviews: totalReviews,
    rating,
  };
};

export default UseReviews;
